<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <!-- ชื่อ-นามสกุล
เบอร์โทร
ระดับ (ปส. , ภาค, จังหวัด) *เป็น dropdown
เลือก ปส.
เลือก ภาค
เลือก จังหวัด

name
tel
user_type (99, 1, 2, 3)
m_group
m_area
m_province -->
      <h1 style="font-weight: 800; color: #577eba">เพิ่มผู้ใช้งาน</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="pt-8"
            ><span class="pt-4">สถานะ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>ชื่อ-นามสกุล</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="name"
              placeholder="ชื่อ-นามสกุล"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>หมายเลขโทรศัพท์</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-text-field
              v-model="tel"
              v-mask="'##########'"
              placeholder="หมายเลขโทรศัพท์"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>ระดับ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-select
              v-model="user_type"
              :items="user_typeItems"
              item-text="text"
              item-value="value"
              placeholder="ระดับ"
              solo
              dense
              :rules="rules.required"
            ></v-select
          ></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3" sm="3" class="pt-0"
            ><span>เลือก ปส.</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-autocomplete
              v-model="m_group"
              :items="m_groupItems"
              item-text="m_group"
              placeholder="เลือก ปส."
              solo
              dense
              :rules="rules.required"
              @change="getm_area(m_group)"
            ></v-autocomplete
          ></v-col>
          <v-col
            cols="12"
            md="3"
            sm="3"
            class="pt-0"
            v-if="user_type !== '1' && user_type !== '99'"
            ><span>เลือก ภาค</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col
            cols="12"
            md="9"
            sm="9"
            v-if="user_type !== '1' && user_type !== '99'"
          >
            <v-autocomplete
              v-model="m_area"
              :items="m_areaItems"
              item-text="m_area"
              placeholder="เลือก ภาค"
              solo
              dense
              :rules="rules.required"
              @change="getm_province(m_area)"
            ></v-autocomplete
          ></v-col>
          <v-col
            cols="12"
            md="3"
            sm="3"
            class="pt-0"
            v-if="user_type !== '1' && user_type !== '2' && user_type !== '99'"
            ><span>เลือก จังหวัด</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col
            cols="12"
            md="9"
            sm="9"
            v-if="user_type !== '1' && user_type !== '2' && user_type !== '99'"
          >
            <v-autocomplete
              v-model="m_province"
              :items="m_provinceItems"
              item-text="m_province"
              placeholder="เลือก จังหวัด"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete
          ></v-col>

          <v-row class="ma-5" align="center" justify="end">
            <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
            <v-btn @click="submit()" color="primary">บันทึก</v-btn>
          </v-row>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";
import { Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      activeFlag: "",
      name: "",
      tel: "",
      user_type: "",
      user_typeItems: [
        { text: "แอดมิน", value: '99' },
        { text: "ปส.", value: '1' },
        { text: "ภาค", value: '2' },
        { text: "จังหวัด", value: '3' },
      ],

      m_group: "",
      m_groupItems: [],
      m_area: "",
      m_areaItems: [],
      m_province: "",
      m_provinceItems: [],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      productOptionList: [],
      isInstallment: false,
      //
    };
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.description = html;
    },
    // onEditorChangetermDesc({ quill, html, text }) {
    //   console.log("editor change!", quill, html, text);
    //   this.termDesc = html;
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    UploadImage() {
      for (let i = 0; i < this.DataImage.length; i++) {
        const element = this.DataImage[i];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          var resultReader = reader.result;
          var url = URL.createObjectURL(element);
          this.product_image.push({
            image_data: resultReader,
            url: url,
            name: this.DataImage[i].name,
            id: "-1",
          });
        };
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    cancel() {
      this.$router.push("manageuser");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoliceWeb"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const productData = {
          activeFlag: this.activeFlag,
          name: this.name,
          tel: this.tel,
          user_type: this.user_type,
          m_group: this.m_group,
          m_area: this.m_area,
          m_province: this.m_province,
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/users/${this.id}`,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขผู้ใช้งานสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขผู้ใช้งานไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async addproductOptionList() {
      this.productOptionList.push({
        optionName: this.optionName,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async getm_area(val) {
      console.log("val", val);
      const m_areaItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findAreaByGroup?group=${this.m_group}`
      );
      this.m_areaItems = m_areaItems.data.data;
      console.log("this.m_areaItems", this.m_areaItems);
    },
    async getm_province(val) {
      console.log("val", val);
      const m_provinceItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findProvinceByArea?area=${this.m_area}`
      );
      this.m_provinceItems = m_provinceItems.data.data;
      console.log("this.m_provinceItems", this.m_provinceItems);
    },
  },
  async created() {
    var userdata = JSON.parse(Decode.decode(localStorage.getItem("userdata")));
    console.log('userdata',userdata);
    this.id = userdata.id;
    this.name = userdata.name
    this.tel = userdata.tel
    this.user_type = userdata.user_type
    this.m_area = userdata.m_area
    this.m_group = userdata.m_group
    this.m_province = userdata.m_province
    this.activeFlag = userdata.activeFlag
    const m_groupItems = await this.axios.get(
      `${process.env.VUE_APP_API}/orgs`
    );
    this.m_groupItems = m_groupItems.data.data;
    console.log("this.m_groupItems", this.m_groupItems);
  },
  watch: {
    user_type(val) {
      console.log({ val });
    },
  },
};
</script>